import React from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import "./layout.css"
import "@fontsource/poppins"
import Email from './images/EmailLogoSVG'
import GithubLogoSVG from './images/GithubLogoSVG'
import LinkedInLogoSVG from './images/LinkedInLogoSVG'
import { Helmet } from "react-helmet"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <div className="my-0 w-full p-0">
        <main className="mt-20 mx-5 md:mx-10">
          {children}
          {/* contact button */}
          <AniLink to="/contact" cover bg="yellow" direction="up">
            <div className="w-6 h-6 bg-black rounded-full">
              <Email className="fixed right-0 bottom-0 m-5 z-10 py-1 px-3 rounded-full shadow" style={{ backgroundColor:'#ffff00'}} />
            </div>
          </AniLink>
        </main>
          <footer className="my-6 text-sm text-center" >
            © {new Date().getFullYear()} Betty Leung &nbsp; 
            <a href="https://github.com/bleunggithub" rel="noreferrer" target="_blank"><GithubLogoSVG style={{display:'inline'}}/>&nbsp;</a>&nbsp;
            <a href="https://www.linkedin.com/in/bettyckleung/" rel="noreferrer" target="_blank"><LinkedInLogoSVG style={{display:'inline'}}/>&nbsp;</a>
          </footer>
        </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
