import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import PropTypes from "prop-types"
import React from "react"
import LogoImage from "./images/LogoImage"

const Header = () => (
  <nav className="mb-4 my-4 mx-auto px-4 fixed left-0 right-0 mt-0 pt-4" style={{backgroundColor:'white', zIndex:'10'}} >

    <ul className="list-none flex justify-center xs:text-xs">
      <li className="pt-3 mr-8">
        <Link to="/me" className="no-underline hover:text-primary-400" >
          Me
        </Link>
      </li>
      <li className="pt-3 mr-8">
        <Link to="/work" className="no-underline hover:text-primary-400" >
          Work
        </Link>
      </li>
      <li className="mr-8 justify-self-center">
        <AniLink to="/" cover bg="yellow" direction="up" className="no-underline" >
        <div style={{ width: `48px` }}>
            <LogoImage />
          </div>
        </AniLink>
      </li>
      <li className="pt-3 mr-8">
        <Link to="/app/login" className="no-underline hover:text-primary-400" >
          CV
        </Link>
      </li>
      <li className="pt-3">
        <Link to="/notes" className="no-underline hover:text-primary-400" >
          Notes
        </Link>
      </li>
    </ul>

  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
